import Vue from 'vue'
import Router from 'vue-router'
const home = () => import('./views/home.vue')
const error404 = () => import('./views/home.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
      path: '*',
      name: 'error404',
      component: error404
    }
  ],
  scrollBehavior(to) {
      document.getElementById('app').scrollIntoView();
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth'
        };
      }
      return { x: 0, y: 0 };  // Go to the top of the page if no hash
  }
})
